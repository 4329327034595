import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AlertController, IonDatetime, ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { MoneyService } from '../../services/money.service';
import { UserService } from '../../services/user.service';
import { YmService } from '../../services/ym.service';

import { PvzSelectComponent } from '../../modals/pvz-select/pvz-select.component'
import { UserSelectComponent } from '../../modals/user-select/user-select.component'

@Component({
  selector: 'app-money-details-edit',
  templateUrl: './money-details-edit.component.html',
  styleUrls: ['./money-details-edit.component.scss'],
})
export class MoneyDetailsEditComponent implements OnInit {

  @ViewChild('datePicker') datePicker : IonDatetime
  @Input() status: string;
  @Input() editingItem: any;
  dataForm: FormGroup;
  kinds: any;
  userPvzs: any;
  userUsers: any;
  dataUpdated: string;
  dataUpdatedUserName: string;
  dateMax: string; // максимальная дата для выбора
  fakeUsername;
  fakePvzAddress;

  constructor(private alertCtrl: AlertController, public api: ApiService, private formBuilder: FormBuilder, private modalCtrl: ModalController, private money: MoneyService, public user: UserService, private ym: YmService) {
    this.kinds = [];
    this.userPvzs = [];
    this.userUsers = [];
    this.dateMax = new Date(Date.now() + 4 * 365 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10);
  }

  async ngOnInit() {
    this.ym.hit('money-details-edit', { params: { title: 'Окно редактирования финансовой транзакции' } });

    if (this.editingItem) {
      if (this.editingItem.employee_name)
        this.fakeUsername = this.editingItem.employee_name
      if (this.editingItem.pvz_address)
        this.fakePvzAddress = this.editingItem.pvz_address

      this.dataForm = this.formBuilder.group({
        date: [, [Validators.pattern(/(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0,1,2])\.(19|20)\d{2}/), Validators.required]],
        type: [2, Validators.required],
        kind_id: [, Validators.required],
        pvz_id: [],
        employee_id: [],
        recurrent_period: [0],
        status: [this.editingItem?.status == 1 || this.status && parseInt(this.status) == 1 ? true : false, Validators.required],
        value: [, Validators.required],
        comment: []
      });

      // сохраняем старое значение даты, чтобы было понятно насколько изменять даты у других периодических транзакций
      if (this.editingItem.recurrent_period)
        this.editingItem.old_date = this.editingItem.date;

      // удаляем значения статуса, т.к. подставили нужный выше в форме
      let filteredItem = { ...this.editingItem };
      //delete filteredItem.status;

      // если дата не указана (добавляем из другого раздела), то проставляем сейчас
      if (!filteredItem.date)
        filteredItem.date = new Date(Date.now() - new Date().getTimezoneOffset() * 60 * 1000).toISOString().slice(0, 10)

      this.dataForm.patchValue(filteredItem);

      this.dataForm.get('date').setValue(this.api.formatInputDate(filteredItem.date, 'dd.mm.yyyy'))
      
      // не даем делать повторяющиеся транзакции, когда добавляем транзакцию для выплаты
      if (this.editingItem.salary_id || this.editingItem.request_id) {
        this.dataForm.get('recurrent_period').disable();
        this.dataForm.get('type').disable();
        if (this.editingItem.salary_id) {
          this.dataForm.get('employee_id').disable();
          this.dataForm.get('value').disable();
        }
        if (this.editingItem.request_id) {
          this.dataForm.get('pvz_id').disable();
        }
      }

      this.setRequiredInputs();

      // если обновляем повторающуюся транзакцию, то блокируем изменение пвз, иначе могут быть конфликты с другими уже созданными
      if (this.dataForm.value.recurrent_period) {
        this.dataForm.get('pvz_id').disable();
        this.dataForm.get('employee_id').disable();
      }

      // при обновлении не даем менять периодичностЬ, чтобы не было конфликтов
      this.dataForm.get('recurrent_period').disable();

      this.dataUpdated = this.editingItem.updated;
      this.dataUpdatedUserName = this.editingItem.updated_name;

      await this.api.loadingPresent();
    } else {
      this.dataForm.get('date').setValue(this.api.formatInputDate(new Date(Date.now() - new Date().getTimezoneOffset() * 60 * 1000).toISOString(), 'dd.mm.yyyy'))

      if (!this.user.data.subActive)
        this.dataForm.get('recurrent_period').disable();   
    }

    this.getKinds();
    this.getPvzs();
    this.getUsers();
  }

  private getKinds() {
    this.api.get('money_details_kinds')
      .subscribe({
        next: (res: any) => {
          if (res.success) {
            this.kinds = res.items.sort((a: any, b: any) => { return a.name > b.name ? 1 : -1 });
          } else {
            this.api.toastPresent('Не удалось загрузить виды транзакций. Повторите попытку позже.');
            this.close();
          }
        }
      });
  }

  private setEmployeePvz() {
    for (let i = 0; i < this.userUsers.length; i++) {
      if (this.dataForm.get('employee_id').getRawValue() == this.userUsers[i].id) {
        this.fakeUsername = this.userUsers[i].name;
        
        if (this.userUsers[i].pvzs) {
          let pvzAddress = this.userUsers[i].pvzs.split(';')[0].slice(this.userUsers[i].pvzs.indexOf('|') + 1)
          for (let p = 0; p < this.userPvzs.length; p++) {
            if (this.userPvzs[p].pvz_address == pvzAddress) {
              this.dataForm.get('pvz_id').setValue(this.userPvzs[p].id);
              this.fakePvzAddress = pvzAddress;
              break;
            }
          }
        }
        break;
      }
    }
  }

  userChanged() {
    // проставляем прикрепленный ПВЗ, если зарплату начисляем
    this.setEmployeePvz();
  }

  async statusChanged(e) {
    if (!this.user.data.subActive && !e.detail.checked) {
      this.dataForm.get('status').setValue(true)
      return this.api.toastPresent('Подключите тариф "ПРОФИ", чтобы добавлять запланированные транзакции и строить прогноз средств на счёте по повторяющимся платежам.')
    }
  }

  setCalendarDate() {
    if (this.dataForm.get('date').valid) {
      this.datePicker.reset(this.api.formatInputDate(this.dataForm.get('date').value, 'yyyy-mm-dd'));
      this.dataForm.get('status').setValue(new Date(this.api.formatInputDate(this.dataForm.get('date').value, 'yyyy-mm-dd')).getTime() <= new Date(this.api.formatInputDate(null, 'yyyy-mm-dd')).getTime())
    }
  }

  setInputDate(e) {
    if (document.querySelector('.datetime-select.datetime-ready') && document.getElementsByClassName('month-year-picker-open').length == 0) {
      this.dataForm.get('date').setValue(this.api.formatInputDate(e.detail.value, 'dd.mm.yyyy'))
      e.target.confirm(true)
    }

    this.dataForm.get('status').setValue(new Date(e.detail.value).getTime() < new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getTime())
  }

  private getPvzs() {
    this.api.getPvzs()
      .subscribe({
        next: (res: any) => {
          this.api.loadingDismiss();
          if (res.success) {
            this.userPvzs = res.items;
            this.setEmployeePvz();
          } else {
            this.api.toastPresent('Не удалось загрузить список ПВЗ. Повторите попытку позже.');
            return this.modalCtrl.dismiss(null, 'close');
          }
        }
      });
  }

  getUsers() {
    this.api.getUsers()
      .subscribe({
        next: (res: any) => {
          this.api.loadingDismiss();
          if (res.success) {
            this.userUsers = res.items.sort((a: any, b: any) => { return a.name > b.name ? 1 : -1 });
            // проставляем пвз, если начисляем зарплату
            this.setEmployeePvz();
          } else {
            this.api.toastPresent('Не удалось загрузить список сотрудников. Повторите попытку позже.');
            return this.modalCtrl.dismiss(null, 'close');
          }
        }
      });
  }

  clearPvz() {
    this.dataForm.patchValue({ pvz_id: null });
    this.fakePvzAddress = null;
  }

  async showSelect(type: string) {
    let modal;
    switch (type) {
      case 'pvz':
        modal = await this.modalCtrl.create({
          component: PvzSelectComponent,
          componentProps: { id: this.dataForm.get('pvz_id').value },
          cssClass: 'pvz-select-modal',
        });
        await modal.present();
    
        var { data, role } = await modal.onDidDismiss();
    
        if (role == 'pvz') {
          this.dataForm.patchValue({ pvz_id: data.item.id })
          this.fakePvzAddress = data.item.pvz_address
        }
        break;
      case 'user':
        modal = await this.modalCtrl.create({
          component: UserSelectComponent,
          componentProps: { id: this.dataForm.get('employee_id').value },
          cssClass: 'user-select-modal',
        });
        await modal.present();
    
        var { data, role } = await modal.onDidDismiss();
    
        if (role == 'selected') {
          this.dataForm.patchValue({ employee_id: data.item.id })
          this.fakeUsername = data.item.name
        }
        break;
    }
  }

  clearUser() {
    this.dataForm.patchValue({ employee_id: null });
    this.fakeUsername = null
  }

  async setRequiredInputs() {
    if (this.dataForm.value.kind_id == 3 || this.dataForm.value.kind_id == 4)
      this.dataForm.get('pvz_id').addValidators(Validators.required);
    else
      this.dataForm.get('pvz_id').clearValidators();

    this.dataForm.get('pvz_id').updateValueAndValidity();

    if (this.dataForm.value.kind_id == 5)
      this.dataForm.get('employee_id').addValidators(Validators.required);
    else
      this.dataForm.get('employee_id').clearValidators();
    
    this.dataForm.get('employee_id').updateValueAndValidity();

    if (this.dataForm.get('kind_id').value == -1) {
      const confirm = await this.alertCtrl.create({
        message: `Укажите название назначения (макс. 30 символов):`,
        inputs: [
          {
            type: 'text',
            value: ''
          }
        ],
        buttons: [{
            text: 'Отмена',
            role: 'cancel'
          },
          {
            text: 'Добавить',
            role: 'confirm',
            handler: async (data) => {    
              if (!data[0] || data[0] && (data[0].length < 1 || data[0].length > 30)) {
                this.api.toastPresent('Укажите название до 30 символов.');
                return false;
              }
              await this.api.loadingPresent();
              this.api.post('money_details_kind', { name: data[0] }, true)
                .subscribe({
                  next: (res: any) => {
                    this.api.loadingDismiss();
                    if (res.success) {
                      this.kinds.push({ id: res.id, name: data[0] })
                      this.dataForm.get('kind_id').setValue(res.id);
                    } else {
                      this.api.toastPresent(res.message);
                      if (res.code == 401) {
                        return this.modalCtrl.dismiss(null, 'close');
                      }
                    }
                  }
                });
            },
          }
        ],
      });
  
      await confirm.present();
      this.dataForm.get('kind_id').setValue(null);
    }
  }

  async save(force_recurrent?: boolean, update_mode?: number) {
    if (this.dataForm.valid) {
      let data = { ...this.dataForm.getRawValue() };
      data.status = data.status ? 1 : 2;
      data.date = this.api.formatInputDate(data.date, 'yyyy-mm-dd')

      if (this.editingItem) {
        data.id = this.editingItem.id;
        // добавляем id выплаты/запроса, если для нее делаем
        if (this.editingItem.salary_id)
          data.salary_id = this.editingItem.salary_id
        if (this.editingItem.request_id)
          data.request_id = this.editingItem.request_id

        data.recurrent_id = this.editingItem.recurrent_id;
          
        if (this.editingItem.old_date)
          data.old_date = this.editingItem.old_date;
      }
      if (force_recurrent)
        data.force = true;

      // если обновляем рекуррентную транзакцию, то спрашиваем, что обновить
      if (data.recurrent_period && data.id && !update_mode) {
        const alert = await this.alertCtrl.create({
          header: 'Что обновить?',
          message: `Вы собираетесь обновить повторяющуюся транзакцию. Вы хотите изменить данные только у этой транзакции или у всех последующих тоже?`,
          buttons: [{
              text: 'Отмена',
              role: 'cancel'
            },
            {
              text: 'Только эту',
              role: 'confirm',
              handler: () => {
                this.save(null, 1);
              },
            },
            {
              text: 'Все последующие',
              role: 'confirm',
              handler: () => {
                this.save(null, 2);
              },
            }
          ],
        });
    
        await alert.present();
      } else {
        if (update_mode)
          data.update_mode = update_mode;

        await this.api.loadingPresent();

        this.api.post('money_details', data, true)
          .subscribe({
            next: async (res: any) => {
              this.api.loadingDismiss();
              if (res.success) {
                // если при проверке выяснилось, что рекуррентная транзакция с таким видом уже есть, то выводим предупреждение
                if (res.exists) {
                  const alert = await this.alertCtrl.create({
                    header: 'Вы уверены?',
                    message: `Повторяющаяся транзакция на сумму ${ res.value } ₽ для выбранного ПВЗ уже существует. Вы действительно хотите добавить ещё?`,
                    buttons: [{
                        text: 'Отмена',
                        role: 'cancel'
                      },
                      {
                        text: 'Добавить',
                        role: 'confirm',
                        handler: () => {
                          this.save(true);
                        },
                      }
                    ],
                  });
              
                  await alert.present();
                } else {
                  this.api.toastPresent(update_mode == 2 ? "Транзакции успешно обновлены." : "Транзакция успешно обновлена.");
                  return this.modalCtrl.dismiss({ id: res.id }, 'saved');
                }
              } else {
                this.api.toastPresent(res.message);
                if (res.code == 401) {
                  return this.modalCtrl.dismiss(null, 'close');
                }
              }
            }
          });
      }
    } else {
      this.api.toastPresent("Указаны некорректные данные в форме. Проверьте ещё раз введённую информацию.");
    }
  }

  async del() {
    let result = await this.money.delete(this.editingItem)
    if (result) {
      this.modalCtrl.dismiss(null, 'saved')
    }
  }

  close() {
    return this.modalCtrl.dismiss(null, 'close');
  }
}
