import { Injectable } from '@angular/core';

import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  data: {
    uuid: string, // ID устройства
    id: number,
    email: string,
    name: string,
    role: number, // 0 - руководитель, 1 - администратор, 2 - менеджер, 3- помощник
    roleName: string, 
    subType: number, // 0 - триал, 1 - активная, 2 - про
    subUntil: string, // до какого активна подписка
    subActive: boolean, // активна подписка или нет
    subPrice: number, // сколько стоит подписка на 1 пвз
    subLicenses: number, // количество купленных лицензий
    pushAllowed: boolean, // разрешены пуши или нет
    hasNewAlerts: boolean, // есть новые алерты
    hasNewRequests: number, // есть невыполненные запросы
    hasNewShiftsRequests: number, // есть необработанные запросы смен
    hasTodayUsersEvents: number, // есть дни рождения или годовщина приема на работу сегодня
    dashboardWidgets: any, // список виджетов на дашборде
    canAddUsers: boolean, // может ли админ добавлять новых пользователей
    accessToMoney: boolean, // есть ли у админа доступ к финансам
    accessToSalary: boolean, // есть ли доступ к своей зарплате
    limitedAccess: boolean, // ограничен ли админ конкретными ПВЗ, чтобы мог добавлять только с привязкой
    showMenu: boolean, // показать левое меню или нет на десктопе
    showNPSButton: boolean,
    settings: {
      globalSettings: any, // настройки назначенные руководителем
      appSettings: any, // настроки системы в целом
      pvzsSettings: any,
      shiftsSettings: any, // настройки Графика работ
      statsSettings: any,
      usersSettings: any, // настройки Сотрудники
      // при добавлении поправить в функции applySettings, app.component инициализацию, api.syncUserData, api user_settings
    },
    permissions: number[] // права доступа пользователя
    //accessRights: number, // битовая маска доступа к разделам и функциям deprecated
    /* 
    0 - Доступ к Панель управления (всегда)
    1 - Доступ к Графику работ (просмотр своих смен и тех, кто вместе в смене)
      + виджет Ваши смены
      + виджет Сегодня работают
    32768 - Доступ к Графику работ (просмотр своих и всех смен на прикрепленных пвз)
      + виджет Сегодня работают
      + виджет Ваши смены
    524288 - Доступ к Графику работ (просмотр всех пвз)
      + виджет Сегодня работают
      + виджет Ваши смены
    2 - Доступ к Графику работ (редактирование прикрепленные)
      + виджет Сегодня работают
      + виджет Ваши смены
      + просмотр фактических отметок
    1048576 - Доступ к Графику работ (редактирование все)
      + виджет Сегодня работают
      + виджет Ваши смены
      + просмотр фактических отметок
    4 - Доступ к Зарплата (просмотр своей)
    268435456 - Доступ к Зарплата (просмотр прикрепленных)
    536870912 - Доступ к Зарплата (просмотр всех)
    8 - Доступ к Зарплата (редактирование прикрепленных) 
      + доступ к управлению формулами оплаты в окне выбора формулы
      + управление фин. транзакциями зарплаты
      + доступ к управлению формулами
    2097152 - Доступ к Зарплата (редактирование всех)
      + доступ к управлению формулами оплаты в окне выбора формулы
      + управление фин. транзакциями зарплаты
      + доступ к формулам
    16 - Доступ к Статистика (просмотр за свои смены)
    32 - Доступ к Статистика (редактирование прикрепленных)
      + виджет Статистика
      + показ статы в денежном эквиваленте
    4194304 - Доступ к Статистика (редактирование всех)
      + виджет Статистика
      + показ статы в денежном эквиваленте
    64 - Доступ к Финансы (редактирование прикрепленных)
      + показ и импорт баланса и доплат в Статистике
      + виджет Оборот средств
      + виджет Ближайшие платежи
    8388608 - Доступ к Финансы (редактирование всех)
      + показ и импорт баланса и доплат в Статистике
      + виджет Оборот средств
      + виджет Ближайшие платежи
    128 - Доступ к Заявки (просмотр)
      + виджет Последние заявки
    256 - Доступ к Заявки (редактирование прикрепленных)
      + управление фин. транзакциями заявок
      + виджет Последние заявки
    16777216 - Доступ к Заявки (редактирование всех)
      + управление фин. транзакциями заявок
      + виджет Последние заявки
    512 - Доступ к Сотрудники (просмотр прикрепленных)
      + показ карточки сотрудника
      + фильтр по сотрудникам
    134217728 - Доступ к Сотрудники (просмотр всех)
      + показ карточки сотрудника
      + фильтр по сотрудникам
    1024 - Доступ к Сотрудники (редактирование прикрепленных)
      + доступ ко всем полям сотрудников
      + редактирование сотрудников в графике работ
      + показ карточки сотрудника
      + доступ к добавлению сотрудников из окна селекта
      + редактирование сотрудников
      + фильтр по сотрудникам
    33554432 - Доступ к Сотрудники (редактирование всех)
      + доступ ко всем полям сотрудников
      + управление должностями
      + редактирование сотрудников в графике работ
      + показ карточки сотрудника
      + доступ к добавлению сотрудников из окна селекта
      + редактирование сотрудников
      + фильтр по сотрудникам
    2048 - Доступ к ПВЗ (просмотр прикрепленных)
      + фильтр по ПВЗ
      + виджет фильтр по Группам
      + фильтр по группам
    67108864 - Доступ к ПВЗ (просмотр всех)
      + фильтр по ПВЗ
      + виджет фильтр по Группам
      + фильтр по группам
    4096 - Доступ к ПВЗ (редактирование прикрепленных)
      + фильтр по ПВЗ
      + редактирование пвз в графике работ
      + виджет фильтр по Группам
      + фильтр по группам
      + редактирование пвз
    65536 - Доступ к ПВЗ (редактирование всех)
      + фильтр по ПВЗ
      + редактирование пвз в графике работ
      + виджет фильтр по Группам
      + фильтр по группам
      + управление группами
      + удаление пвз
      + добавление пвз
    8192 - Доступ к Истории действий (своих)
    131072 - Доступ к Истории действий (всех прикрепленных)
    262144 - Доступ к Истории действий (всех)
    16384 - Доступ к Тарифный план
      + блок на панели до какого подписка
    1073741824 - просмотр живого видео с камер
    */
  }
  subPlans: any // объект с данными о доступных планах и их стоимости

  constructor(private storage: StorageService) {
    this.newUserData();
    this.subPlans = {
      prices: {
        profi: 100
      },
      sales: {
        pvzs: [
          { count: 50, sale: 0.5 },
          { count: 10, sale: 0.8 },
          { count: 2, sale: 0.95 },
        ],
        months: [
          { count: 12, sale: 1 },
          { count: 3, sale: 1 },
        ]
      }
    }
  }

  newUserData() {    
    this.data = {
      uuid: null,
      id: null,
      email: '',
      name: '',
      role: null,
      roleName: null,
      subType: null,
      subUntil: null,
      subActive: false,
      subPrice: null,
      subLicenses: 0,
      pushAllowed: null,
      hasNewAlerts: false,
      hasNewRequests: 0,
      hasNewShiftsRequests: 0,
      hasTodayUsersEvents: 0,
      dashboardWidgets: [],
      canAddUsers: false,
      accessToMoney: false,
      accessToSalary: false,
      limitedAccess: false,
      showMenu: true,
      showNPSButton: false,
      permissions: [],
      //accessRights: 1,
      settings: {
        globalSettings: {},
        appSettings: {
          dark_theme: true,
          selected_group: null,
        },
        pvzsSettings: null,
        shiftsSettings: {
          show_days_shifts: true,
          show_managers_shifts: true,
          show_icon_salary: true,
          show_icon_fact: true,
          show_icon_comment: true,
          show_shift_times: true,
          pvzs_per_page: 6,
          hidden_users: [],
          show_hidden_users: false,
          shifts_from_today: false,
          //show_shift_hours: false,
          show_shifts_registry: false
        },
        statsSettings: {
          charts_unit: 'count',
          charts_style: 0,
          charts_group_by: 'days',
          charts_series: 'pvz_address',
          charts_height: 300,
          chart_balance: true,
          chart_balance_width: 1,
          chart_balance_order: 1,
          chart_recieved: true,
          chart_recieved_width: 1,
          chart_recieved_order: 2,
          chart_ready: false,
          chart_ready_width: 0.5,
          chart_ready_order: 3,
          chart_returned: false,
          chart_returned_width: 0.5,
          chart_returned_order: 4,
          chart_users: true,
          chart_users_width: 0.5,
          chart_users_order: 5,
          chart_rating: true,
          chart_rating_width: 0.5,
          chart_rating_order: 6,
          chart_ready_reward: false,
          chart_ready_reward_width: 0.5,
          chart_ready_reward_order: 7,
          chart_rating_reward: false,
          chart_rating_reward_width: 0.5,
          chart_rating_reward_order: 8,
          chart_repack_reward: false,
          chart_repack_reward_width: 0.5,
          chart_repack_reward_order: 9,
          chart_bag_reward: false,
          chart_bag_reward_width: 0.5,
          chart_bag_reward_order: 10,
        },
        usersSettings: {
          compact_list: false,
          show_today_events_icon: true
        }
      }
    }
  }

  /**
   * Сохраняет данные пользователя в память
   */
  saveUserData() {
    this.storage.set('userData', this.data);
  }

  /**
   * Применяет настройки, полученные с сервера
   * @param settings объект с настройками от API
   */
  applySettings(settings) {
    try {
      if (settings.globalSettings)
        this.data.settings.globalSettings = { ...this.data.settings.globalSettings, ...JSON.parse(settings.globalSettings) }
      if (settings.appSettings)
        this.data.settings.appSettings = { ...this.data.settings.appSettings, ...JSON.parse(settings.appSettings) }
      if (settings.pvzsSettings)
        this.data.settings.pvzsSettings = { ...this.data.settings.pvzsSettings, ...JSON.parse(settings.pvzsSettings) }
      if (settings.shiftsSettings)
        this.data.settings.shiftsSettings = { ...this.data.settings.shiftsSettings, ...JSON.parse(settings.shiftsSettings) }
      if (settings.statsSettings)
        this.data.settings.statsSettings = { ...this.data.settings.statsSettings, ...JSON.parse(settings.statsSettings) }
      if (settings.usersSettings)
        this.data.settings.usersSettings = { ...this.data.settings.usersSettings, ...JSON.parse(settings.usersSettings) }
    } catch(e) {
      console.error('Возникла ошибка при обработке настроек учетной записи. Для исправления свяжитесь с поддержкой сервиса.');
    }
  }

  /**
   * Собирает данные для отправки на сервер в строку
   * @param { string } ключ настроек для синхронизации
   * @returns { Object } строка JSON-объекта с данными для отправки
   */
  getSyncData(key: string): Object {
    let syncData: any = {};
    syncData[key] = JSON.stringify(this.data.settings[key])
    return syncData
  }

  /**
   * Переключает показ меню на десктопе
   */
  toggleMenu() {    
    this.data.showMenu = !this.data.showMenu;
    this.saveUserData();
  }

  /**
   * Генерация рандомного UUID
   * @returns { string } рандомный uuid 
   */
  generateUUID(): string {
    let chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
    let randomstring = '';
    for (let i = 0; i < 32; i++) {
      let rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }
    return randomstring;
  }

  /**
   * Проверяет права пользователя к указанной странице
   * @param page название страницы
   * @returns { boolean } есть доступ или нет
   */
  checkPageAccessRights(page: string): boolean {
    if (this.data.role == 0) {
      return true
    } else {
      switch (page) {
        case 'shifts':
          return this.checkPermissions([1, 4, 2, 3, 5]);
        case 'salary':
          return this.checkPermissions([6, 9, 7, 8, 10, ]) ||
            // доступно также с правами редактирования графика работ
            this.checkPermissions([4, 5]) 
        case 'salary/rewards':
          return this.checkPermissions([6, 9, 10]);
        case 'salary/formulas':
          return this.checkPermissions([9, 10]) ||
            // доступно также с правами редактирования графика работ
            this.checkPermissions([4, 5])
        case 'stats':
          return this.checkPermissions([11, 12, 13]) ||
            // доступно также с правами к финансам, чтобы было видно Баланс
            this.checkPermissions([14, 15])
        case 'money':
        case 'money/complete':
        case 'money/plan':
          return this.checkPermissions([14, 15]);
        case 'requests':
          return this.checkPermissions([16, 17, 18]);
        case 'users':
          return this.checkPermissions([19, 21, 20, 22]);
        case 'pvzs':
          return this.checkPermissions([23, 25, 24, 26]);
        case 'plans':
          return this.checkPermissions([30]);
        case 'contests':
          return this.checkPermissions([32, 33, 34, 35, 36]);
        default:
          return false;
      }
    }
  }

  /**
   * Проверяет права доступа на совпадение с любым из перечисленных в передаваемом массиве
   * @param { number[] } permissions массив прав доступа для проверки с пользовательским 
   * @returns { boolean } есть права или нет
   */
  checkPermissions (permissions: number[]): boolean {
    return this.data.permissions.some(p => permissions.includes(p))
  }

  /**
   * Собирает указанные права в битовую маску, удаляет лишние поля и возвращает переданный объект
   * @param { object } data редактируемый объект с указаными правами из формы
   * @returns { object } объект с удалёнными полями прав и посчитанной битовой маской
   */
  calculatePermissions(data) {
    data.permissions = [];
    if (data.permission_shifts) data.permissions.push(data.permission_shifts)
    delete data.permission_shifts
    if (data.permission_salary) data.permissions.push(data.permission_salary)
    delete data.permission_salary
    if (data.permission_stats) data.permissions.push(data.permission_stats)
    delete data.permission_stats
    if (data.permission_money) data.permissions.push(data.permission_money)
    delete data.permission_money
    if (data.permission_requests) data.permissions.push(data.permission_requests)
    delete data.permission_requests
    if (data.permission_users) data.permissions.push(data.permission_users)
    delete data.permission_users
    if (data.permission_pvzs) data.permissions.push(data.permission_pvzs)
    delete data.permission_pvzs
    if (data.permission_plan) data.permissions.push(data.permission_plan)
    delete data.permission_plan
    if (data.permission_history) data.permissions.push(data.permission_history)
    delete data.permission_history
    if (data.permission_videocam) data.permissions.push(data.permission_videocam)
    delete data.permission_videocam
    if (data.permission_contests) data.permissions.push(data.permission_contests)
    delete data.permission_contests

    if (data.permissions.length == 0) {
      data.permissions.push(0)
      delete data.permissions_dashboard
    }

    return data;
  }

  /**
   * Преобразует поле с правами доступа в отдельные ключи для использования в шаблонах и формах
   * @param { object } data объект с данными пользователя/роли
   * @returns объект с преобразованными правами доступа в отдельные ключи
   */
  decodePermissions(data) {
    // функция возвращает значение права доступа для работы с ним в дальнейшем
    let getPermission = (permissions: number[]): number => {
      if (data.permissions) {
        let permIndex: number;
        if (data.permissions.some((p: number, i: number) => { 
          if (permissions.includes(p)) {
            permIndex = i;
            return true;
          } else {
            return false
          }
        })) {
          return data.permissions[permIndex]
        } else {
          return null
        }
      } else
        return null;
    }

    if (data.permission_dashboard = getPermission([0])) {
      return data;
    }

    if (data.permission_shifts = getPermission([1, 4, 2, 3, 5])) {
      if (getPermission([4]))
        data.permission_shifts_limited = true;
      if (getPermission([5]))
        data.permission_shifts_full = true;
    }
    if (data.permission_salary = getPermission([6, 9, 7, 8, 10])) {
      if (getPermission([9]))
        data.permission_salary_limited = true;
      if (getPermission([10]))
        data.permission_salary_full = true;
    }
    if (data.permission_stats = getPermission([11, 12, 13])) {
      if (getPermission([12]))
        data.permission_stats_limited = true;
      if (getPermission([13]))
        data.permission_stats_full = true;
    }
    if (data.permission_money = getPermission([14, 15])) {
      if (getPermission([14]))
        data.permission_money_limited = true; 
      if (getPermission([15]))
        data.permission_money_full = true;
    }
    if (data.permission_requests = getPermission([16, 17, 18])) {
      if (getPermission([17]))
        data.permission_requests_limited = true;
      if (getPermission([18]))
        data.permission_requests_full = true;
    }
    if (data.permission_users = getPermission([19, 21, 20, 22])) {
      if (getPermission([21]))
        data.permission_users_limited = true;
      if (getPermission([22]))
        data.permission_users_full = true;
    }
    if (data.permission_pvzs = getPermission([23, 25, 24, 26])) {
      if (getPermission([25]))
        data.permission_pvzs_limited = true;
      if (getPermission([26]))
        data.permission_pvzs_full = true;
    }
    data.permission_plan = getPermission([30])
    data.permission_history = getPermission([27, 28, 29])
    data.permission_videocam = getPermission([31])
    if (data.permission_contests = getPermission([32, 33, 34, 35, 36])) {
      if (getPermission([35]))
        data.permission_contests_limited = true;
      if (getPermission([36]))
        data.permission_contests_full = true;
    }

    return data
  }
}
